import { useCallback, useMemo, useState } from 'react';
import type {
  LicensePlate,
  Location,
  WorkOrder,
  WorkOrderStepMoveInventoryInput,
} from '@store/services/api.generated';
import { useNavigate } from 'react-router-dom';
import {
  useLazyGetLocationsQuery,
  usePutLicensePlatesByLicensePlateIdMoveMutation,
  usePostWorkOrdersByWorkOrderIdMoveMutation,
} from '@store/services/api';
import { useKeyenceScanner } from '@scanner/hooks';
import { useToast } from '@hooks/useToast';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDisclosure } from '@chakra-ui/react';
import { useSetFieldErrors } from '@hooks/useSetFieldErrors';

type FormData = {
  destination_location_id: string;
};

type MovementType = 'putaway' | 'move' | 'putaway-work-order';

export type MoveLpnSuccessLocation = {
  state: {
    lpn: string;
    location: string;
    destination: string;
    workOrder?: WorkOrder;
  };
};

const locationSchema = yup.object({
  destination_location_id: yup.string().required('Please scan a location barcode'),
});

export const useMoveLpnScan = ({
  licensePlate,
  location: originalLocation,
  movementType,
  navigationPath,
  workOrder,
  workOrderStep,
}: {
  licensePlate?: LicensePlate;
  location?: Location;
  movementType: MovementType;
  navigationPath: string;
  workOrder?: WorkOrder;
  workOrderStep?: WorkOrderStepMoveInventoryInput;
}) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    setValue,
    clearErrors,
    setError,
  } = useForm<FormData>({
    resolver: yupResolver(locationSchema),
  });
  const setFieldErrors = useSetFieldErrors({ setError });
  const navigate = useNavigate();
  const { errorToast } = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { successFeedback, errorFeedback } = useKeyenceScanner();
  const [selectedDestination, setSelectedDestination] = useState<Location>();

  const [searchLocations] = useLazyGetLocationsQuery();
  const [putMovement, { isLoading: isPuttingMove }] =
    usePutLicensePlatesByLicensePlateIdMoveMutation();
  const [putawayWorkOrder, { isLoading: isPuttingAwayWorkOrder }] =
    usePostWorkOrdersByWorkOrderIdMoveMutation();

  const resetForm = useCallback(() => {
    setSelectedDestination(undefined);
    setValue('destination_location_id', '');
    onClose();
  }, [onClose, setSelectedDestination, setValue]);

  const setLocationSearchFieldError = useCallback(
    (message: string) => {
      errorFeedback();
      setError('destination_location_id', { message });
    },
    [setError, errorFeedback]
  );

  const handleMoveLpn = useCallback(async () => {
    try {
      if (movementType === 'putaway-work-order') {
        await putawayWorkOrder({
          workOrderId: workOrder?.id ?? '',
          body: {
            destination_location_id: selectedDestination?.id ?? '',
            license_plate_number: licensePlate?.number ?? '',
          },
        });
      } else {
        await putMovement({
          licensePlateId: licensePlate?.id!,
          body: {
            destination_location_id: selectedDestination?.id,
          },
        }).unwrap();
      }
    } catch (e) {
      errorFeedback();
      const didSetErrors = setFieldErrors(e);
      if (!didSetErrors) {
        setLocationSearchFieldError('There was an error selecting this location, try again.');
      }
      errorToast('Error making location change.');
      onClose();
      return;
    }

    resetForm();
    successFeedback();
    navigate(navigationPath, {
      state: {
        lpn: licensePlate?.number!,
        location: originalLocation?.name!,
        destination: selectedDestination?.name!,
        workOrder,
      },
    });
  }, [
    movementType,
    resetForm,
    successFeedback,
    navigate,
    navigationPath,
    licensePlate?.number,
    licensePlate?.id,
    onClose,
    originalLocation?.name,
    putawayWorkOrder,
    selectedDestination?.name,
    selectedDestination?.id,
    workOrder,
    putMovement,
    errorFeedback,
    setFieldErrors,
    errorToast,
    setLocationSearchFieldError,
  ]);

  const handleScan = useMemo(
    () =>
      handleSubmit(async ({ destination_location_id }: FormData) => {
        clearErrors();
        let locationsResult;
        try {
          locationsResult = await searchLocations({
            search: destination_location_id,
          }).unwrap();
        } catch (err) {
          const didSetErrors = setFieldErrors(err);
          if (!didSetErrors) {
            setLocationSearchFieldError('There was an error selecting this location, try again.');
          }
          return;
        }

        if ((locationsResult?.data ?? []).length > 1) {
          setLocationSearchFieldError('Invalid location scan. Please scan a valid location.');
          return;
        }

        const destinationLocation = locationsResult?.data?.[0];

        if (!destinationLocation) {
          setLocationSearchFieldError('Invalid location scan. Please scan a valid location.');
          return;
        }

        if (destinationLocation.id === originalLocation?.id) {
          setLocationSearchFieldError(
            'Cannot move LPN to the same location it is currently in. Please scan a different location.'
          );
          return;
        }

        if (destinationLocation.type === 'shipped') {
          setLocationSearchFieldError(
            'Cannot move LPN to shipped location. Mark the order it belongs to as shipped to complete the order.'
          );
          return;
        }

        if (destinationLocation?.type === 'staging' && movementType === 'putaway') {
          setLocationSearchFieldError(
            'Cannot move LPN to staging location. Use the Move LPN function instead.'
          );
          return;
        }

        const isMovingLpnToTriageLocation =
          destinationLocation?.type === 'other' && destinationLocation?.subtype === 'triage';

        if (
          movementType === 'move' &&
          originalLocation?.type === 'equipment' &&
          !isMovingLpnToTriageLocation
        ) {
          setLocationSearchFieldError(
            'Cannot move LPN from equipment location outside of picking or return to stock process.'
          );
          return;
        }

        if (movementType === 'move' && destinationLocation?.type === 'equipment') {
          setLocationSearchFieldError(
            'Cannot move LPN to equipment location outside of picking process.'
          );
          return;
        }

        if (
          originalLocation?.type !== 'staging' &&
          destinationLocation?.type === 'staging' &&
          movementType === 'move'
        ) {
          setLocationSearchFieldError(
            'Cannot move LPN to staging location outside of receiving or picking process.'
          );
          return;
        }

        const hasLocationTypeMismatch =
          destinationLocation?.type !== workOrderStep?.destination_location_type;
        const hasLocationSubtype = workOrderStep?.destination_location_subtype;
        const hasLocationSubTypeMismatch =
          hasLocationSubtype &&
          workOrderStep?.destination_location_subtype !== destinationLocation?.subtype;
        if (
          movementType === 'putaway-work-order' &&
          (hasLocationTypeMismatch || hasLocationSubTypeMismatch)
        ) {
          const subtypeMessage = hasLocationSubtype
            ? ' - ' + workOrderStep?.destination_location_subtype
            : '';
          setLocationSearchFieldError(
            `Location needs to be of type: ${workOrderStep?.destination_location_type}${subtypeMessage}`
          );
          return;
        }
        setSelectedDestination(destinationLocation);
        onOpen();
      }),
    [
      clearErrors,
      handleSubmit,
      originalLocation,
      movementType,
      onOpen,
      searchLocations,
      setFieldErrors,
      setLocationSearchFieldError,
      workOrderStep?.destination_location_subtype,
      workOrderStep?.destination_location_type,
    ]
  );

  return {
    control,
    isPuttingAwayWorkOrder,
    isPuttingMove,
    handleScan,
    handleMoveLpn,
    isOpen,
    isSubmitting,
    resetForm,
    selectedDestination,
    setValue,
  };
};
