import * as yup from 'yup';

export const updateLocationSchema = yup.object({
  location_sequence: yup
    .number()
    .integer('Must be whole number')
    .positive('Must be greater than 0')
    .required()
    .typeError('Must be greater than 0'),
  active: yup.boolean().required(),
  type: yup.string().required(),
  subtype: yup.string().nullable(true),
  pick_equipment_group_id: yup.string().nullable(true),
  max_capacity: yup
    .number()
    .integer('Must be a valid number.')
    .typeError('Must be a valid number.')
    .nullable(true)
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  replenishment_threshold: yup
    .number()
    .integer('Must be a valid number.')
    .typeError('Must be a valid number.')
    .nullable(true)
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
});
