import { createSlice } from '@reduxjs/toolkit';
import type { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store';
import type {
  Activity,
  Building,
  CycleCount,
  InboundOrder,
  Item,
  LicensePlate,
  Location,
  OutboundOrder,
  Printer,
  Product,
  User,
} from '@store/services/api.generated';
import type { FulfilledActionFromAsyncThunk } from '@reduxjs/toolkit/dist/matchers';
import type { MutationThunk } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import type { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query';
import type { $FIXME } from '@type-utils';

/**
 * Misc. Notes
 *
 * - 'outbounds' are intentionally not included here. As of 10/5/2021,
 *   we perform optimistic updates in api.ts for these changes as the underlying changes need to block certain
 *   user actions.
 */

export type KnownTopic =
  | 'activities'
  | 'adjustments'
  | 'buildings'
  | 'cycle_counts'
  | 'cycle_counts_created'
  | 'cycle_counts_started'
  | 'cycle_counts_review'
  | 'cycle_counts_closed'
  | 'cycle_counts_finished'
  | 'cycle_count_results'
  | 'cycle_count_locations'
  | 'locations'
  | 'zones'
  | 'inbounds'
  | 'items'
  | 'license_plates'
  | 'outbounds'
  | 'printers'
  | 'products'
  | 'shippers'
  | 'users'
  | 'units_of_measure';

type State = Record<KnownTopic, number> & {
  tracked_request_ids: string[];
};

const initialState: State = {
  activities: 0,
  adjustments: 0,
  buildings: 0,
  cycle_counts: 0,
  cycle_counts_created: 0,
  cycle_counts_started: 0,
  cycle_counts_review: 0,
  cycle_counts_closed: 0,
  cycle_counts_finished: 0,
  cycle_count_results: 0,
  cycle_count_locations: 0,
  locations: 0,
  zones: 0,
  inbounds: 0,
  items: 0,
  license_plates: 0,
  outbounds: 0,
  printers: 0,
  products: 0,
  shippers: 0,
  users: 0,
  units_of_measure: 0,
  tracked_request_ids: [],
};

type ChannelAction<Payload> = {
  type: string;
  payload: {
    event: string;
    payload: {
      data: Payload;
      meta: { requestId: string };
    };
  };
};

const isChannelType = (action: AnyAction) =>
  action.type === 'channelEvent' && typeof action.payload?.event === 'string';

const hasUserPayload = (action: AnyAction): action is ChannelAction<User> =>
  isChannelType(action) && action?.payload?.event.startsWith('user');

const hasAdjustmentsPayload = (action: AnyAction): action is ChannelAction<$FIXME> =>
  isChannelType(action) && action?.payload?.event.startsWith('adjustment');

const hasProductPayload = (action: AnyAction): action is ChannelAction<Product> =>
  isChannelType(action) &&
  ['bulk_product', 'product'].some((prefix) => action?.payload?.event.startsWith(prefix));

const hasActivityPayload = (action: AnyAction): action is ChannelAction<Activity> =>
  isChannelType(action) && action?.payload?.event.startsWith('activity');

const hasLocationPayload = (action: AnyAction): action is ChannelAction<Location> =>
  isChannelType(action) &&
  ['bulk_location', 'location'].some((prefix) => action?.payload?.event.startsWith(prefix));

const hasPrinterPayload = (action: AnyAction): action is ChannelAction<Printer> =>
  isChannelType(action) && action?.payload?.event.startsWith('printer');

const hasItemPayload = (action: AnyAction): action is ChannelAction<Item> =>
  isChannelType(action) &&
  ['bulk_item', 'item'].some((prefix) => action?.payload?.event.startsWith(prefix));

const hasInboundPayload = (action: AnyAction): action is ChannelAction<InboundOrder> =>
  isChannelType(action) &&
  ['inbound_created', 'inbound_updated', 'inbound_canceled'].includes(action?.payload?.event);

const hasLicensePlatePayload = (action: AnyAction): action is ChannelAction<LicensePlate> =>
  isChannelType(action) && action?.payload?.event.startsWith('license_plate');

// We're tracking batch events here as well as they could impact the display on outbounds - e.g. a
// user assigned to a batch is changed would be relevant and should be reflected in the UI.
const hasOutboundPayload = (action: AnyAction): action is ChannelAction<OutboundOrder> =>
  isChannelType(action) &&
  [
    'outbound_created',
    'outbound_updated',
    'batch_created',
    'batch_updated',
    'outbound_unbatched',
    'outbound_canceled',
  ].includes(action?.payload?.event);

const hasCycleCountResultPayload = (action: AnyAction): action is ChannelAction<CycleCount> =>
  isChannelType(action) && action?.payload?.event.startsWith('cycle_count_result');

const hasCycleCountLocationPayload = (action: AnyAction): action is ChannelAction<CycleCount> =>
  isChannelType(action) && action?.payload?.event.startsWith('cycle_count_location');

const hasCycleCountCreatedPayload = (action: AnyAction): action is ChannelAction<CycleCount> =>
  isChannelType(action) &&
  // only match on top level cycle count events to prevent
  // matching on nested associations e.g. `cycle_count_result_updated`
  ['cycle_count_created', 'cycle_count_started', 'cycle_count_closed'].includes(
    action?.payload?.event
  );

const hasCycleCountStartedPayload = (action: AnyAction): action is ChannelAction<CycleCount> =>
  isChannelType(action) &&
  // only match on top level cycle count events to prevent
  // matching on nested associations e.g. `cycle_count_result_updated`
  ['cycle_count_started', 'cycle_count_review', 'cycle_count_closed'].includes(
    action?.payload?.event
  );

const hasCycleCountReviewPayload = (action: AnyAction): action is ChannelAction<CycleCount> =>
  isChannelType(action) &&
  // only match on top level cycle count events to prevent
  // matching on nested associations e.g. `cycle_count_result_updated`
  ['cycle_count_review', 'cycle_count_finished'].includes(action?.payload?.event);

const hasCycleCountClosedPayload = (action: AnyAction): action is ChannelAction<CycleCount> =>
  isChannelType(action) &&
  // only match on top level cycle count events to prevent
  // matching on nested associations e.g. `cycle_count_result_updated`
  ['cycle_count_closed'].includes(action?.payload?.event);

const hasCycleCountFinishedPayload = (action: AnyAction): action is ChannelAction<CycleCount> =>
  isChannelType(action) &&
  // only match on top level cycle count events to prevent
  // matching on nested associations e.g. `cycle_count_result_updated`
  ['cycle_count_finished'].includes(action?.payload?.event);

const hasBuildingPayload = (action: AnyAction): action is ChannelAction<Building> =>
  isChannelType(action) && action?.payload?.event.startsWith('building');

const isFulfilledMutation = (
  action: AnyAction
): action is FulfilledActionFromAsyncThunk<MutationThunk> & {
  meta: { baseQueryMeta: FetchBaseQueryMeta };
} => action.type === 'api/executeMutation/fulfilled';

const canUpdateCount = (state: State, action: ChannelAction<any>) =>
  !state.tracked_request_ids.find((key) => key === action.payload.payload.meta.requestId);

const slice = createSlice({
  name: 'realtime',
  initialState,
  reducers: {
    resetTopicCount: (state, { payload }: PayloadAction<KnownTopic>) => {
      state[payload] = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(hasUserPayload, (state, action) => {
        if (canUpdateCount(state, action)) {
          state.users += 1;
        }
      })
      .addMatcher(hasAdjustmentsPayload, (state, action) => {
        if (canUpdateCount(state, action)) {
          state.adjustments += 1;
        }
      })
      .addMatcher(hasProductPayload, (state, action) => {
        if (canUpdateCount(state, action)) {
          state.products += 1;
        }
      })
      .addMatcher(hasActivityPayload, (state, action) => {
        if (canUpdateCount(state, action)) {
          state.activities += 1;
        }
      })
      .addMatcher(hasLocationPayload, (state, action) => {
        if (canUpdateCount(state, action)) {
          state.locations += 1;
        }
      })
      .addMatcher(hasPrinterPayload, (state, action) => {
        if (canUpdateCount(state, action)) {
          state.printers += 1;
        }
      })
      .addMatcher(hasItemPayload, (state, action) => {
        if (canUpdateCount(state, action)) {
          state.items += 1;
        }
      })
      .addMatcher(hasBuildingPayload, (state, action) => {
        if (canUpdateCount(state, action)) {
          state.buildings += 1;
        }
      })
      .addMatcher(hasInboundPayload, (state, action) => {
        if (canUpdateCount(state, action)) {
          state.inbounds += 1;
        }
      })
      .addMatcher(hasOutboundPayload, (state, action) => {
        if (canUpdateCount(state, action)) {
          state.outbounds += 1;
        }
      })
      .addMatcher(hasCycleCountCreatedPayload, (state, action) => {
        if (canUpdateCount(state, action)) {
          state.cycle_counts_created += 1;
        }
      })
      .addMatcher(hasCycleCountStartedPayload, (state, action) => {
        if (canUpdateCount(state, action)) {
          state.cycle_counts_started += 1;
        }
      })
      .addMatcher(hasCycleCountReviewPayload, (state, action) => {
        if (canUpdateCount(state, action)) {
          state.cycle_counts_review += 1;
        }
      })
      .addMatcher(hasCycleCountClosedPayload, (state, action) => {
        if (canUpdateCount(state, action)) {
          state.cycle_counts_closed += 1;
        }
      })
      .addMatcher(hasCycleCountFinishedPayload, (state, action) => {
        if (canUpdateCount(state, action)) {
          state.cycle_counts_finished += 1;
        }
      })
      .addMatcher(hasCycleCountResultPayload, (state, action) => {
        if (canUpdateCount(state, action)) {
          state.cycle_count_results += 1;
        }
      })
      .addMatcher(hasCycleCountLocationPayload, (state, action) => {
        if (canUpdateCount(state, action)) {
          state.cycle_count_locations += 1;
        }
      })
      .addMatcher(hasLicensePlatePayload, (state, action) => {
        if (canUpdateCount(state, action)) {
          state.license_plates += 1;
        }
      })
      .addDefaultCase((state, action) => {
        // We're assuming that any mutation this client performs will want to skip the channel event that provides it in the `meta`
        if (isFulfilledMutation(action)) {
          const requestId = action.meta?.baseQueryMeta?.response?.headers?.get('x-request-id');
          if (requestId) {
            state.tracked_request_ids.unshift(requestId);
          }
          if (state.tracked_request_ids.length >= 20) {
            state.tracked_request_ids.pop();
          }
        }
      });
  },
});

export const { resetTopicCount } = slice.actions;

export default slice.reducer;

export const selectUnviewedCountsByChannelTopic = (state: RootState, topic: KnownTopic) =>
  state.realtime[topic] || 0;
